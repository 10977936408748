<template>
  <LayoutTelas id="tela_notas" :titulo="this.$route.meta.titulo">
    <!-- <MonitorForm/> -->
  </LayoutTelas>
</template>

<script>

export default {
  data() {
    return {
      dataInventario: '',
    }
  },
  components: {
    MonitorForm
  }
  
}
</script>

<style lang="scss" scoped>
@import "@/common/scss/breakpoints.scss";

#tela_notas {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  @media (min-width: $medium-size) {
    min-width: $small-size;
  }
}

.paginacao {
  display: flex;
  justify-content: flex-end;

  &__seletor {
    margin: 0;
  }
}
</style>